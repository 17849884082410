@media print {
  *,
  *:before,
  *:after {
    font-family: "Helvetica" !important;
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    font-size: 12px !important;
    font-weight: "400" !important;
  }
  p {
    font-size: 12px !important;
  }
  h3,
  h4,
  h5 {
    font-size: 12px !important;
    font-weight: "400" !important;
  }
}
